import React from 'react'
import PropTypes from 'prop-types'
import WysiwygComponent from '../../components/Wysiwyg'
import Container from '../../components/Container'

const Wysiwyg = ({ content }) => (
  <Container variant="wide" tw="py-10 tablet:py-0">
    <WysiwygComponent content={content} />
  </Container>
)

Wysiwyg.propTypes = {}

Wysiwyg.defaultProps = {}

export default Wysiwyg
